import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Fade, Grid, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import marc from "img/portraits/marc.jpg";
import widder from "img/portraits/katharina_widder_smaller.jpg";
import React, { useEffect, useState } from 'react';
import TextTransition from "react-text-transition";

const team = [
    {
        imageId: 0,
        name: "Marc Wagner",
        role: "Physiotherapeut / Inhaber",
        areas: [
            "Manuelle Therapie nach osteopathischem Konzept",
            "Manuelle Lymphdrainage",
            "Gerätegestützte Krankengymnastik",
            "MTT (Medizinische Trainingstherapie)",
            "Sportphysiotherapeut",
            "Taping (klassisch / Kineso)",
            ""
        ]
    },
    {
        imageId: 1,
        name: "Katharina Widder",
        role: "Physiotherapeutin",
        areas: [
            "Manuelle Lymphdrainage",
            "Gerätegestützte Krankengymnastik",
            ""
        ]
    }
]

function Team() {

    const teamImages = [marc, widder]
    
    const [memberIndex, setMemberIndex] = useState(0)

    const onLeft = () => {
        if (memberIndex === 0) {
            setMemberIndex(team.length - 1)
        } else {
            setMemberIndex(memberIndex - 1)
        }
    }

    const onRight = () => {
        setMemberIndex((memberIndex + 1) % team.length)
    }

    
    const [areaList, setAreaList] = useState(team[0].areas)


    useEffect(() => {
        setAreaList(team[memberIndex].areas)
        
    }, [memberIndex])

    return (
        <section id="team">
            <Fade delay={0}>
                <Typography variant='h2' sx={{pt: 10, pl: {xs: 2, sm:5, md: 10}}}>
                    Unser Team
                    <Fade delay={150}>
                        <div className="underline">
                            &nbsp;
                        </div>
                    </Fade>
                </Typography>
            </Fade>
            <Grid container sx={{pl: {xs: 2, sm:5, md: 10}}} alignItems={"flex-start"}>
                <Grid item xs={12} sm={6} lg={5} sx={{position: "relative"}} className="crossfade-team">
                    {teamImages.map((i, idx) => <img key={team[idx].name} src={i} alt={`${team[idx].name}`} className={idx === memberIndex ? 'fadeImage appear' : 'fadeImage fadeout'}/>)}
                </Grid>
                <Grid spacing={2} container direction="column" sx={{pt: {xs: 0, md: 10}}} item xs={12} sm={6} lg={7}>
                   {team.length > 1 && <Grid item container direction="row" spacing={1} sx={{mt: {xs: -3.5}}}> 
                        <Grid item>
                            <IconButton onClick={onLeft} aria-label="next-team-member" sx={{backgroundColor: "primary.main", "&:hover": {
                                backgroundColor: "primary.dark"
                            }}}>
                                <ChevronLeftIcon/>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={onRight} aria-label="previous-team-member" sx={{backgroundColor: "primary.main", "&:hover": {
                                backgroundColor: "primary.dark"
                            }}}>
                                <ChevronRightIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>}
                    <Grid item>
                        
                            <Typography sx={{color: "primary.main", fontWeight: "bold"}}>
                                Name
                            </Typography>

                            <Typography>
                                <TextTransition
                                    noOverflow
                                    text={ team[memberIndex].name }
                                />
                            </Typography>
                                
                                
                    </Grid>
                    <Grid item>
                        <Typography sx={{color: "primary.main", fontWeight: "bold"}}>
                            Tätigkeit
                        </Typography>
                        <Typography>
                            <TextTransition
                                noOverflow
                                text={ team[memberIndex].role }
                            />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography sx={{color: "primary.main", fontWeight: "bold"}}>
                            Qualifikationen
                        </Typography>
                        <List sx={{width: "fit-content", pt: 0}}>
                            <Fade cascade>
                                <>
                                {areaList.map((a, idx) => {
                                    return (
                                    <ListItem disablePadding dense disableGutters divider sx={{pr: {xs: 0, md: 5}, display: idx == (areaList.length - 1) ? 'none' : 'inherit'}} key={`teamaufgabe-${idx}-${memberIndex}`}>
                                        <ListItemText
                                            primary={a}
                                        />
                                    </ListItem>
                                )})}
                                </>
                            </Fade>
                        </List>
                    </Grid>
                    
                </Grid>
            </Grid>
            
            
            <div className="fadeout-to-offers">
                &nbsp;
            </div>
        </section>
    )
}

export default Team
