import OutboundOutlinedIcon from '@mui/icons-material/OutboundOutlined';
import { Button, Hidden, Typography } from '@mui/material';
import React, { useEffect } from 'react';


const MAP_ICON = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	width="47.9px" height="63.9px" viewBox="0 0 47.9 63.9" style="enable-background:new 0 0 47.9 63.9;" xml:space="preserve">
<path style="fill:#6ABF4B;" d="M24,0C10.7,0,0,10.7,0,24l0,0c0,13.2,20,39.9,24,39.9S48,37.2,48,24C47.9,10.7,37.2,0,24,0z M24,39.9
	c-8.8,0-16-7.2-16-16s7.2-16,16-16s16,7.2,16,16S32.8,39.9,24,39.9z"/>
<g>
	
		<path style="fill:#585C5D;stroke:#585C5D;stroke-width:0.923;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="
		M18.6,35.4c0.1-0.1,0.2-0.1,0.3-0.2c0.2-2.2,1-4,1.9-5.9c0.7-1.5,1.9-2.8,3.2-3.9c0.6-0.5,0.6-0.5,1.1,0c2.3,1.7,4.9,2.8,7.8,2.8
		c4.3,0.1,8.1-1.3,11.4-3.9c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0,0-0.1,0c-0.3,0.1-0.6,0.2-0.9,0.3c-4.2,1.7-8.3,1.8-12.4-0.2
		c-0.8-0.4-1.6-0.8-2.3-1.3c-0.5-0.3-0.9-0.7-1.4-1.1c-2.2-1.7-4-3.7-5.8-5.8c-1-1.2-2-2.4-3.2-3.3c-2.7-2-5.5-2.1-8.5-0.8
		c-1.4,0.6-2.6,1.5-3.7,2.5c-0.2,0.2-0.5,0.5-0.5,0.9c0.4,0,0.6-0.2,0.9-0.3c1.2-0.5,2.3-1.1,3.6-1c1.3,0.1,2.6,0.4,3.8,1
		c2.1,1,3.7,2.5,5.3,4.1c0.7,0.7,0.5,0.6,0,1.2c-0.1,0.2-0.2,0.3-0.3,0.5c-1.5,2.2-2.4,4.6-3.1,7.2c-0.7,2.9-1.3,6.6-1,9.6
		C15.5,37.4,16.5,36.7,18.6,35.4L18.6,35.4z"/>
</g>
<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#585C5D;" d="M35,13.5c0,2.7-2.4,5.4-5,5.7c-2.2,0.3-3.8-1.2-3.8-3.5
	c0-2.7,2.4-5.4,5-5.7C33.3,9.8,35,11.3,35,13.5z"/>
</svg>
`
function Map() {

    const ref = React.createRef()

    const initMap = () => {
        const H = window.H;
        //A9XjDBeNonyIt8APrDGgNJEWlphEGimrgUVuH0AN--U
        var platform = new H.service.Platform({
            'apikey': '8FrYo_l-mkrrhH5pyaBzunvdEttiH1KIirgj1d9Y7v8'
        });

        const defaultLayers = platform.createDefaultLayers();

        let newMap = new H.Map(
            ref.current,
            defaultLayers.raster.terrain.map,
            {
                zoom: 15.5,
                center: { lat: 50.11637602986484, 
                    lng: 8.84757726634734, },
                autoColor: true,
            });

        //var mapEvents = new H.mapevents.MapEvents(newMap);
        //var behavior = new H.mapevents.Behavior(mapEvents);
        var ui = H.ui.UI.createDefault(newMap, defaultLayers);

        var group = new H.map.Group()
        ui.getControl('mapsettings').setDisabled(false)
        newMap.addObject(group)
        let icon = new H.map.Icon(MAP_ICON)
        let marker = new H.map.Marker({
            lat: 50.11637602986484, 
            lng: 8.84757726634734,
        }, {icon: icon});
        group.addObject(marker)
        //let marker = new H.map.Marker(map.screenToGeo(window.innerWidth/2, window.innerHeight/2))
        //console.log(map.getViewPort(), window.innerWidth/2, window.innerHeight/2)
        //group.addObject(marker)
    }

    useEffect(() => {
        //initMap()
    }, [])

    return (
        <div ref={ref} className='internal-map-wrapper' style={{ width: "100%", position: "relative", overflow: 'hidden' }} >
            <Button variant='contained' style={{position: "absolute", zIndex: 99, bottom: "1em", left: "50%", transform: "translate(-50%)"}}>
                <a rel="noreferrer" style={{textDecoration: "none", color: "black", display: "flex", alignItems: "center"}} target="_blank" href="https://www.google.com/maps/dir//PhysioLife+M%C3%BChlheim,+Philipp-Reis-Stra%C3%9Fe+6,+63165+M%C3%BChlheim+am+Main/@50.1161155,8.8439253,15.25z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47bd118073803b21:0xdb6e4bb5f0473b0e!2m2!1d8.8475858!2d50.1163651">
                    Naviagtion mit Google <OutboundOutlinedIcon sx={{ml: 1}}/>
                </a>
            </Button>
            <Hidden mdDown>
                <Typography
                    variant='body2'
                    style={{padding: '5px', position: "absolute", zIndex: 99, top: "1em", left: "1em", backgroundColor: "rgba(255,255,255,0.95)", maxWidth: '25vw'}}
                >
                <b>Parkplätze</b> an der Praxis vorhanden
                oder im Südring, Otto-Hahn-Straße,
                Zeppelinring
                <br/><br/>
                <b>Busverbindung</b><br/>
                Linie 31 Haltestelle Ärztehaus Südring
                Linie 120 Haltestelle Ulmenstraße oder
                Mühlheim Bahnhof (weiter mit Linie 31)
                <br/><br/>
                <b>S-Bahn</b><br/>
                S8/S9 Mühlheim Bahnhof
                </Typography>
            </Hidden>
        </div>
    )
}

export default Map
