import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import vitalAtelier from "img/vitalAtelier.jpg";
import React, { useState } from "react";
import MenuBar from "./Menu";

function Footer() {
  const partner = [
    {
      icon: vitalAtelier,
      link: "https://vitalatelier-spannaus.de/",
      name: "Vital Atelier Dr. Corinna Spannus",
    },
  ];

  const openingTimes = [
    {
      day: "Sonntag",
      from: -1,
      to: -1,
    },
    {
      day: "Montag",
      from: 8,
      to: 20,
    },
    {
      day: "Dienstag",
      from: 8,
      to: 20,
    },
    {
      day: "Mittwoch",
      from: 8,
      to: 14,
    },
    {
      day: "Donnerstag",
      from: 8,
      to: 20,
    },
    {
      day: "Freitag",
      from: 8,
      to: 15,
    },
    {
      day: "Samstag",
      from: -1,
      to: -1,
    },
  ];

  const get_today_times = () => {
    const today = new Date();
    const dateOfWeek = today.getDay();
    return [
      ...openingTimes.slice(dateOfWeek),
      ...openingTimes.slice(0, dateOfWeek),
    ];
  };

  const [open, setOpen] = useState(false)

  return (
    <footer>
      <Grid container direction="row" justifyContent={"center"} spacing={1} sx={{ pt: 3 }}>
        {/* <Grid item xs={12} md={4}>
          <Grid item container justifyContent="center" id="partner">
            <Typography variant="h3" sx={{ p: 4.5 }}>
              Unser Partner
            </Typography>
          </Grid>
          <Grid item container justifyContent="center" sx={{ mb: 5 }}>
            {partner.map((p) => (
              <Grid item key={p.link}>
                <a target="_blank" rel="noreferrer" href={p.link}>
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src={p.icon}
                    alt={`${p.name} Logo`}
                  />
                </a>
              </Grid>
            ))}
          </Grid>
        </Grid> */}
        <Grid item xs={12} md={5}>
          <Grid item container justifyContent="center">
            <Typography variant="h3" sx={{ p: 4.5 }}>
              Öffnungszeiten
            </Typography>
          </Grid>
          <Grid
            container
            item
            alignItems="center"
            justifyContent="center"
            direction="column"
            sx={{ mb: 2, fontWeight: "bold" }}
          >
            {get_today_times()
              .slice(0, 1)
              .map((ot, idx) => (
                <Grid
                  key={ot.day}
                  item
                  container
                  justifyContent={"center"}
                  sx={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}
                >
                  <Grid item xs={4}>
                    {ot.day}
                  </Grid>
                  {ot.from !== -1 ? (
                    <Grid item xs={"auto"}>
                      {ot.from < 10 ? "0" : ""}
                      {ot.from}:00 - {ot.to < 10 ? "0" : ""}
                      {ot.to}:00
                    </Grid>
                  ) : (
                    <Grid item xs={"auto"}>
                      Geschlossen
                    </Grid>
                  )}
                </Grid>
              ))}
          </Grid>
          <Grid item sx={{ mb: 2 }}>
            {[
              ...openingTimes.slice(1, openingTimes.length),
              openingTimes[0],
            ].map((ot, idx) => (
              <Grid
                key={ot.day}
                item
                container
                justifyContent={"center"}
                sx={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}
              >
                <Grid item xs={4}>
                  {ot.day}
                </Grid>
                {ot.from !== -1 ? (
                  <Grid item xs={"auto"}>
                    {ot.from < 10 ? "0" : ""}
                    {ot.from}:00 - {ot.to < 10 ? "0" : ""}
                    {ot.to}:00
                  </Grid>
                ) : (
                  <Grid item xs={"auto"}>
                    Geschlossen
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
          <Grid item sx={{ textAlign: "center" }}>
            <Typography variant="h5" sx={{fontWeight: "bold"}}> und nach Vereinbarung </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5}>
          <Grid item container justifyContent="center">
            <Typography variant="h3" sx={{ p: 4.5 }}>
              Kontakt
            </Typography>
          </Grid>
          <Grid item container justifyContent="center" sx={{ mb: 5 }}>
            <Grid container alignItems={"center"} direction="column">
              <Grid item>Philipp-Reis-Straße 6</Grid>
              <Grid item>63165 Mühlheim</Grid>
              <Grid container alignItems="center" direction={"column"}>
                <Grid
                  item
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <a href="tel:06108-975 94 10">06108-975 94 10</a>
                  <br />
                </Grid>
                <Grid
                  item
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <a href="mailto:kontakt@physiolife-muehlheim.de">
                    kontakt@physiolife-muehlheim.de
                  </a>
                  <br />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <MenuBar iconsOnly>
          <Grid item onClick={() => setOpen(true)} sx={{"&:hover": {cursor: "pointer"}}}>Impressum</Grid>
        </MenuBar>
        <Dialog open={open} fullWidth maxWidth={'lg'} onClose={() => setOpen(false)}>
          <DialogTitle>
            <Typography variant="h2">Impressum</Typography>
          </DialogTitle>
          <DialogContent>

<h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
<p>Marc Wagner<br />
Physiotherapiepraxis<br />
Ahornstra&szlig;e 15<br />
63165 M&uuml;hlheim am Main</p>

<h2>Kontakt</h2>
<p>Telefon: 061089759410<br />
Telefax: 061089759412<br />
E-Mail: kontakt@physiolife-muehlheim.de</p>

<h2>Umsatzsteuer-ID</h2>
<p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
DE344206314</p>

<h2>Aufsichtsbeh&ouml;rde</h2>
<p>Regierungspr&auml;sidium Darmstadt<br />
Regierungspr&auml;sidium Darmstadt<br />
Presse, Digitalisierung und Kommunikation<br />
Luisenplatz 2<br />
64283 Darmstadt</p>
<p><a href="http://rp-darmstadt.hessen.de" target="_blank" rel="noopener noreferrer">http://rp-darmstadt.hessen.de</a></p>

<h2>Berufsbezeichnung und berufsrechtliche Regelungen</h2>
<p>Berufsbezeichnung:<br />
Physiotherapeut/in / Krankengymnast/in</p>
<p>Zust&auml;ndige Kammer:<br />
Bundesverband selbstst&auml;ndiger Physiotherapeuten<br />
Gesundheitscampus-S&uuml;d 33, 44801 Bochum</p>
<p>Verliehen in:<br />
Deutschland</p>
<p>Es gelten folgende berufsrechtliche Regelungen:</p>
<p>Regelungen: Masseur- und Physiotherapeutengesetz<br />
 einsehbar unter: <a href="http://www.gesetze-im-internet.de/mphg/BJNR108400994.html" target="_blank" rel="noopener noreferrer">http://www.gesetze-im-internet.de/mphg/BJNR108400994.html</a></p>
<h2>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
<p><strong>Name und Sitz des Versicherers:</strong><br />
Continentale Krankenversicherung a.G.<br />
Ruhrallee 92<br />
44139 Dortmund</p>
<p><strong>Geltungsraum der Versicherung:</strong><br />Deutschland</p>

<h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
<p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

<p>Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a></p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Schließen</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </footer>
  );
}

export default Footer;
