import { Box, Grid, Hidden, Typography, useTheme } from '@mui/material'
import React from 'react'
import Map from "./Map"

function TheOffice() {

    const theme = useTheme()


    return (
        <Box container id="the-office"  sx={{pl:  {lg: 14, md: 7, xs: 3}, pr: {lg: 14, md: 7, xs: 3}, pt: {xs: 5, md: 5}, pb: {xs: 5, md: 10} }}>
            <div className="office-container">
                <Grid container item xs={12} alignItems="center" direction="column">
                    <Grid item xs={12}>
                        <Typography variant='h2' sx={{mt: 5}}>
                            Die Praxis
                        </Typography>
                    </Grid>
                    <Grid item>
                        <div style={{width: `calc(min(50vw, 600px))`, backgroundColor: theme.palette.primary.main, height: "0.2rem", margin: `${theme.spacing(2.5)} 0px`}}/>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" sx={{pl: {xs: 3, sm: 7, lg: 15}, pr: {xs: 3, sm: 7, lg: 15}, pb: {xs: 2.5, lg: 5}, textAlign: 'justify'}}>
                        
Seit dem 01.08.2021 besteht die Praxis im Gewerbegebiet Süd und ist für Privat- und Kassenpatienten eine neue Anlaufstelle in Mühlheim am Main.
Auf <b>328m²</b> befinden sich 6 große Behandlungskabinen, ein Turnraum, ein Geräteraum mit neuen Kraftgeräten der Marke LifeFitness und einer großen Freifläche.
<br/><br/>Die Praxis befindet sich im zweiten Stockwerk und ist per Treppe bzw. Aufzug sehr gut zu erreichen.
Im Hof stehen Ihnen fünf Parkplätze zur Verfügung. Alternativen gibt es in den benachbarten Straßen oder Sie kommen mit den öffentlichen Verkehrsmitteln.

                    </Typography>
                </Grid>
                <Hidden mdUp>
                    <Typography
                        variant='body2'
                        sx={{pl: {xs: 3, sm: 7, lg: 15}, pr: {xs: 3, sm: 7, lg: 15}, pb: {xs: 2.5, lg: 5}, textAlign: 'justify'}}
                    >
                    <b>Parkplätze</b> an der Praxis vorhanden
                    oder im Südring, Otto-Hahn-Straße,
                    Zeppelinring
                    <br/><br/>
                    <b>Busverbindung</b><br/>
                    Linie 31 Haltestelle Ärztehaus Südring
                    Linie 120 Haltestelle Ulmenstraße oder
                    Mühlheim Bahnhof (weiter mit Linie 31)
                    <br/><br/>
                    <b>S-Bahn</b><br/>
                    S8/S9 Mühlheim Bahnhof
                    </Typography>
                </Hidden>
                <Grid item className="map-container">
                    <Map/>
                </Grid>
                
            </div>
        </Box>
    )
}

export default TheOffice
