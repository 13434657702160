import { useTheme } from "@emotion/react";
import {
  Backdrop, Drawer,
  Grid,
  List,
  ListItem, ListItemText,
  Typography,
  useMediaQuery
} from "@mui/material";
import facebook from "img/facebook-square.svg";
import instagram from "img/instagram.svg";
import logo from "img/logo.svg";
import mapIcon from "img/maps.svg";
import React, { useState } from "react";
import { Link, scroller } from "react-scroll";

const links = [
  {
    label: "Über uns",
    to: "about-us",
  },
  {
    label: "Unsere Angebote",
    to: "offers",
  },
  {
    label: "Das Team",
    to: "team",
  },
  {
    label: "Die Praxis",
    to: "the-office",
  },
  {
    label: "FAQ",
    to: "faq",
  },
  {
    label: "Galerie",
    to: "images",
  },
  /* {
    label: "Partner",
    to: "partner",
  }, */
];

const MenuBar = ({ fixed, iconsOnly, children }) => {
  const isMobile = useMediaQuery("(max-width: 1120px)");

  const [anchorIsOpen, setOpen] = useState(false);
  const theme = useTheme();

  const onMenuClick = (to) => {
    scroller.scrollTo(to, {duration: 600, smooth: true});
  }

  return (
    <>
      {isMobile && !iconsOnly && (
        <React.Fragment>
          <Drawer
            anchor={"left"}
            open={anchorIsOpen}
            variant="persistent"
            PaperProps={{ style: { zIndex: 302 } }}
          >
            <List sx={{ pt: 10 }} className="mobileMenuList">
              {links.map((link, index) => (
                <ListItem button key={link.to} onClick={() => onMenuClick(link.to)}>
                  {/*<Link to={link.to} spy={true} smooth={true} duration={600}>*/}
                    <ListItemText primary={link.label} />
                  {/**</Link> */}
                </ListItem>
              ))}
            </List>
          </Drawer>
          <Backdrop
              sx={{ color: '#fff', zIndex: 301}}
              open={anchorIsOpen}
              onClick={() => setOpen(false)}
          />
        </React.Fragment>
      )}
      {isMobile && !iconsOnly && (
        <div
          style={{
            position: "fixed",
            top: theme.spacing(2),
            left: theme.spacing(2),
            zIndex: 303,
          }}
        >
          <button
            onClick={() => setOpen(!anchorIsOpen)}
            className={
              anchorIsOpen ? "opened menu-toggle-button" : "menu-toggle-button"
            }
            aria-expanded={anchorIsOpen}
            aria-label="Main Menu"
          >
            <svg width="100" height="100" viewBox="0 0 100 100">
              <path
                class="line line1"
                d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
              />
              <path class="line line2" d="M 20,50 H 80" />
              <path
                class="line line3"
                d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
              />
            </svg>
          </button>
        </div>
      )}
      <Grid
        container
        flexDirection={"row"}
        alignItems="center"
        className={`menubar ${fixed ? "fixed" : "footer-nav"}`}
      >
        {!iconsOnly && !isMobile && (
          <>
            {links.map((link, idx) => (
              <React.Fragment key={link.to}>
                <Grid item>
                  <Link to={link.to} spy={true} smooth={true} duration={600}>
                    <Typography variant="button">{link.label}</Typography>
                  </Link>
                </Grid>
                {idx !== links.length - 1 && <Grid item>&middot;</Grid>}
              </React.Fragment>
            ))}
            <Grid item xs>
              &nbsp;
            </Grid>
          </>
        )}
        {isMobile && !iconsOnly && (
          <Grid item xs>
            &nbsp;
          </Grid>
        )}
        <Grid item>
          <a href="tel:06108-975 94 10" style={{textDecoration: `0.05em underline ${theme.palette.primary.main}` , color: "black"}}>
            <Typography variant="button" sx={{color: "black", textDecoration: "none"}}>06108-975 94 10</Typography>
          </a>
        </Grid>
        <Grid item>
          <a
            rel="noreferrer"
            href="https://goo.gl/maps/nokBEPfLobyYXZRN6"
            target="_blank"
          >
            <img id="map-icon" alt="google-maps-icon" src={mapIcon} />
          </a>
        </Grid>
        <Grid item>
          <a
            href="https://www.facebook.com/PhysioLife-M%C3%BChlheim-107205638206953"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebook} alt="facebook-icon" />
          </a>
        </Grid>
        <Grid item>
          <a
            href="https://www.instagram.com/physiolife_muehlheim/?hl=de"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="instagram-icon" />
          </a>
        </Grid>
        <Grid item>
          <Link to="hero" spy={true} smooth={true} duration={600}>
            <img src={logo} alt="physiolife-icon" />
          </Link>
        </Grid>
        {children}
      </Grid>
    </>
  );
};

export default MenuBar;
