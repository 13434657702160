import { Box, Grid, Hidden, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef } from 'react'
import CrossfadeImage from 'react-crossfade-image';
import { useState } from 'react';
import images from "img/normal"
import { useTheme } from '@mui/material/styles';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import { Fade } from 'react-awesome-reveal';

function AboutUs() {


    const aboutUsImages = [
        images.physio12
    ]

    const [imageIndex, setImageIndex] = useState(0)

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    let currentTimeout = useRef(null)

    useEffect(() => {
        if (currentTimeout) clearTimeout(currentTimeout)
        currentTimeout = setTimeout(() => setImageIndex(
            (imageIndex + 1) % aboutUsImages.length), 5000)
        return () => clearTimeout(currentTimeout)
    }, [imageIndex])

    const onLeft = () => {
        if (imageIndex === 0) {
            setImageIndex(aboutUsImages.length - 1)
        } else {
            setImageIndex(imageIndex - 1)
        }
    }

    const onRight = () => {
        setImageIndex((imageIndex + 1) % aboutUsImages.length)
    }

    const [touchStart, setTouchStart] = React.useState(0);
    const [touchEnd, setTouchEnd] = React.useState(0);

    function handleTouchStart(e) {
        setTouchStart(e.targetTouches[0].clientX);
    }

    function handleTouchMove(e) {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    function handleTouchEnd() {
        if (touchStart - touchEnd > 150) {
            // do your stuff here for left swipe
            onRight();
        }

        if (touchStart - touchEnd < -150) {
            // do your stuff here for right swipe
            onLeft();
        }
    }

    return (
        <section id="about-us">
            <Grid container direction="column" className="left" style={{ width: isMobile ? '100%' : '50%' }} justifyContent={"center"}>
                <Grid item>
                    <Fade delay={0}>
                        <Typography variant='h2' className="header" sx={{mt: {xs: 5, md: 0}}}>Das sind wir...</Typography>
                    </Fade>
                </Grid>
                <Grid item>
                    <Fade delay={150}>
                        <div className="underline longer">
                            &nbsp;
                        </div>
                    </Fade>
                </Grid>
                <Grid item>
                    <Fade delay={400}>
                        <Typography variant="body1" style={{ textAlign: isMobile ? 'justify' : 'left' }}>
                            PhysioLife setzt sich aus zwei Wörtern zusammen, die das Ziel der Therapie perfekt beschreiben. Ein
                            physiologisches (gesundes / den Lebensvorgängen entsprechendes) Leben wieder herzustellen.
                            <br /><br />
                            Dieses Ziel möchten wir erreichen, indem wir Ihren Körper ganzheitlich betrachten.
                            <br /><br />
                            Um Ihnen eine individuelle und bestmögliche Therapie anbieten zu können, ist deshalb eine ausführliche
                            Befundung das A und O.

                        </Typography>
                    </Fade>
                </Grid>
                <Grid item style={{position: "relative", marginBottom: isMobile ? '3em' : '0px'}} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
                    <Hidden mdUp>
                        <CrossfadeImage
                            containerClass="crossfade-small"
                            src={aboutUsImages[imageIndex]}
                            duration={200}
                            timingFunction={"ease-out"}
                        />
                    </Hidden>
                </Grid>
            </Grid>
            <Hidden mdDown>
                <div className="right">
                    <div className="fadeout-top-image">
                        &nbsp;
                    </div>
                    <Box
                        sx={{
                            backgroundImage: `url(${aboutUsImages[0]})`,
                            height: "100%",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    alt="PhysioLife Team"/>
                   
                </div>
            </Hidden>
            <div className="fadeout-to-offers">
                &nbsp;
            </div>
        </section>
    )
}

export default AboutUs
