import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createBreakpoints } from '@mui/system';
import AboutUs from 'sections/AboutUs';
import Faq from 'sections/Faq';
import Footer from 'sections/Footer';
import { Images } from 'sections/Images';
import Services from 'sections/Services';
import Team from 'sections/Team';
import TheOffice from 'sections/TheOffice';
import './App.css';
import Hero from './sections/Hero';



const breakpoints = createBreakpoints({})

const theme = createTheme({
  typography: {
    fontFamily: [
      "CircleR", "sans-serif", '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    button: {
      [breakpoints.up('md')]: {
        fontSize: "1.15rem",
        textTransform: "none"
      },
      '@media (min-width: 1300px)': {
        fontSize: "1.4rem"
      },
    }
  },
  palette: {
    primary: {
      main:  "#6ABF5D",
    }
  }
});

theme.typography.h2 = {
  ...theme.typography.h2,
  [theme.breakpoints.down('sm')]: {
    fontSize: '36px',
  },
}

theme.typography.h5 = {
  ...theme.typography.h5,
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
  },
}

theme.typography.body1 = {
  [theme.breakpoints.up('md')]: {
    fontSize: '22px',
  },
};

theme.typography.body2 = {
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
  },
};


function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Hero/>
        <AboutUs/>
        <Services/>
        <Team/>
        <TheOffice/>
        <Faq/>
        <Images/>
        <Footer/>
      </ThemeProvider>
    </div>
  );
}

export default App;
