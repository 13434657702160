import { Grid, Slide, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

const faqs = [
    {
        title: "Wie mache ich einen Termin aus ?",
        body: <p>Aktuell bitten wir Sie die Termine per Telefon auszumachen. Sollten Sie Niemanden erreichen, sprechen Sie bitte auf den Anrufbeantworter. Wir versuchen die Anrufe schnellstmöglich zu beantworten.</p>
    },
    {
        title: <span>Was muss ich zum <b>ersten</b> Termin mitbringen ?</span>,
        body: <p>Verordnung, Versichertenkarte, großes Handtuch, Bargeld für die Rezeptgebühr, bequeme Kleidung</p>
    },
    {
        title: "Was muss ich machen, wenn ich einen vereinbarten Termin nicht einhalten kann ?",
        body: <p>
        Wenn Sie einen Termin nicht wahrnehmen können, informieren Sie uns bitte rechtzeitig! 
        Wir müssen Sie an dieser Stelle darauf hinweisen, dass wir Ihnen nicht oder sehr kurzfristig abgesagte Termine (weniger als 24h vor dem vereinbarten Termin) in Rechnung stellen müssen!
        Wir bitten hierfür um Ihr Verständnis.
        </p>
    },
    {
        title: <span>Warum wird eine Rezeptgebühr / Eigenanteil fällig ?<br/></span>,
        body: <p>Im Auftrag der Krankenkassen müssen wir für jedes Rezept eine Rezeptgebühr / Eigenanteil einziehen, 
            da die gesetzlichen Krankenkassen die Kosten einer Heilmittelverordnung nicht komplett übernehmen.
            Bitte beachten Sie, dass <b>keine Kartenzahlung</b> möglich ist !"</p>
    },
    {
        title: "Wie läuft das mit der Honorarvereinbarung bei Privatpatienten ab ?",
        body: <p>Zu Beginn der Physiotherapie vereinbaren wir mit Ihnen das Honorar mittels Honorarvereinbarung. Die Höhe der von Ihnen zu entrichtenden Vergütung für die Behandlung ist Bestandteil des Behandlungsvertrages und ist unabhängig von der Höhe der Kostenerstattung durch Ihre private Krankenversicherung zu zahlen. Sie sollten vor dem Behandlungsbeginn Kontakt zu Ihrer Krankenkasse aufnehmen, um den Kostenrahmen und eine eventuelle Eigenbeteiligung abzuklären. Diese ist abhängig von Ihrem vereinbarten Tarif.
        </p>
    },
    {
        title: "Was muss ich machen wenn eine OP / Reha bevorsteht ?",
        body: <p>Wir empfehlen Ihnen direkt mit ihrem Arzt zu besprechen, ob und wenn ja, welche Heilmittelverordnungen Sie bekommen. Dadurch können wir Ihnen eine optimale Nachsorge anbieten. Sind Sie in einer Rehaklinik gilt im Prinzip das Gleiche. Sprechen Sie ihren Arzt an und vereinbaren Sie am besten noch aus der Reha die Termine.</p>
    },
    {
        title: "Wie lange dauert eine Behandlung ?",
        body: <p>Haben Sie z.B KG oder MT verordnet bekommen sind damit 20 Minuten abgedeckt. Die 20 Minuten beinhalten An- und Auskleiden, der Befund, die Therapie, die Dokumentation des Verlaufes, das eventuelle Schreiben eines Therapieberichtes, und sämtliche organisatorischen Dinge.</p>
    },
    {
        title: "Bieten Sie auch Hausbesuche an ?",
        body: <p>Hausbesuche können wir aktuell leider nicht anbieten</p>
    },
]

function Faq() {


    const [animationDelay, setAnimationDelay] = useState(0)

    useEffect(() => {
        if (animationDelay < Math.ceil(faqs.length / 2) * 2) {
            setTimeout(() => {
                setAnimationDelay(animationDelay + 2)
            }, 50)
        }
    }, [animationDelay])

    return (
        <section id="faq">
            <Grid container direction="row" sx={{width: '100%', height: '100%', pt: 15, pb: 15}} justifyContent="center">
                <Grid item xs={12} container justifyContent={"center"}>
                    <Typography sx={{color: 'primary.main', textAlign: "center"}} variant='overline'>
                        FAQ
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{pb: 5}}>
                    <Typography variant='h2' sx={{textAlign: "center"}}>
                        Haben Sie Fragen?
                    </Typography>
                </Grid>
                {
                    faqs.map((faq, idx) => (
                    
                        <Grid direction={"column"} container xs={12} lg={6} item key={`faq-${idx}`} sx={{pl: {xs: 2.5, sm: 5, md: 10}, pr: {xs: 2.5, sm: 5, md: 10}, pb: 2.5, pt: 2.5}}>
                            <Slide left={(idx % 2) === 0} right={(idx % 2) === 1}>
                                <>
                                <Grid item>
                                    <Typography variant="h5" sx={{color: 'primary.dark'}}>
                                        {faq.title}<br/>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" sx={{textAlign: 'justify'}}>
                                        {faq.body}
                                    </Typography>
                                </Grid>
                                </>
                            </Slide>
                        </Grid>
                ))
                }
            </Grid>
        </section>
    )
}



export default Faq
