import physio1 from "./physio1.jpg";
import physio2 from "./physio2.jpg";
import physio3 from "./physio3.jpg";
import physio4 from "./physio4.jpg";
import physio5 from "./physio5.jpg";
import physio6 from "./physio6.jpg";
import physio7 from "./physio7.jpg";
import physio8 from "./physio8.jpg";
import physio9 from "./physio9.jpg";
import physio10 from "./physio10.jpg";
import physio11 from "./physio11.jpg";
import physio12 from "./physio12.jpg";
import physio13 from "./physio13.jpg";
import physio14 from "./physio14.jpg";
import physio15 from "./physio15.jpg";
import physio16 from "./physio16.jpg";
import physio17 from "./physio17.jpg";
import physio18 from "./physio18.jpg";
import physio19 from "./physio19.jpg";
import physio20 from "./physio20.jpg";
import physio21 from "./physio21.jpg";
import physio22 from "./physio22.jpg";
import physio23 from "./physio23.jpg";
import praxis1 from "./praxis1.jpg"

const images = {
    physio1, physio2, physio3, physio4, praxis1,
    physio5, physio6, physio7, physio8, physio9, physio10, physio11, physio12, physio13
    , physio14, physio15, physio16, physio17, physio18, physio19, physio20, physio21, physio22, physio23,
}

export default images