import { useTheme } from '@emotion/react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, List, ListItem, useMediaQuery } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.primary.main}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    marginBottom: theme.spacing(2),
    backgroundColor: "transparent",
    boxShadow: "3px 2px 5px rgba(0,0,0,0.1)"
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: 'rgba(255,255,255,0.5)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(-90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const services = [
    {
        title: <span>Krankengymnastik <i>(KG)</i></span>,
        text: `Die Krankengymnastik ist eine Behandlungsform, mit der Krankheiten aus fast allen medizinischen
        Fachbereichen therapiert werden. Sie umfasst sowohl aktive als auch passive Therapieformen. Ziel der
        Krankengymnastik ist es, die Bewegungs- und Funktionsfähigkeit des Körpers zu verbessern.`,
        prices: [
            {
                duration: "20 Minuten",
                cost: 30.5
            }
        ]
    },
    {
        title: <span>Manuelle Therapie <i>(MT)</i></span>,
        text: `Ziel der Anwendung ist es, Blockaden im Körper auf sanfte und sichere Art und Weise zu lösen. Durch
        vorsichtiges ziehen, dehnen oder drehen, wird versucht, die Blockaden zu lösen. Am Ende der Therapie
        sollte das Zusammenspiel aus Gelenken, Muskeln und Nerven wieder besser oder gar einwandfrei
        funktionieren. Wie weit der Normalzustand hergestellt werden kann, hängt unter anderem von den
        zugrunde
        liegenden Erkrankungen ab.`,
        prices: [
            {
                duration: "20 Minuten",
                cost: 35.5
            }
        ]
    },{
        title: <span>Klassische Massage <i>(KMT)</i></span>,
        text: `In der klassischen Massage unterscheidet man fünf verschiedene Griffarten, über die er Reize auf das
        behandelte Gewebe ausübt. Normalerweise werden dabei Haut, Unterhaut (Subdermis) und Muskulatur
        mechanisch, systematisch und schichtweise bearbeitet.`,
        prices: [
            {
                duration: "20 Minuten",
                cost: 25.5
            }
        ]
    },{
        title: <span>Krankengymnastik am Gerät <i>(KGG)</i></span>,
        text: `Bei der Krankengymnastik am Gerät trainiert der Patient/Patientin aktiv. Einen standardisierten
        Übungsablauf gibt es nicht. Vielmehr wird mit Ihnen ein persönliches Training zusammengestellt. Das
        Training findet an modernen Trainingsgeräten statt. Zum Einsatz kommen aber auch Hanteln,
        Gymnastikbälle, uvm.`,
        prices: [
            {
                duration: "45-60 Minuten",
                cost: 53.5
            }
        ]
    },{
        title: <span>Sportphysiotherapie</span>,
        text: `Die Sportphysiotherapie zielt speziell auf die Behandlung von Sportlern ab. Sie dient sowohl der
        Vorbeugung, als auch der Rehabilitation von Verletzungen, Unfällen und Operationen. Bei dieser
        Therapieform werden verschiedenste Methoden der Physiotherapie eingesetzt.`,
        prices: []
    },{
        title: <span>Manuelle Lymphdrainage <i>(MLD)</i></span>,
        text: `Die manuelle Lymphdrainage gilt als Teil der Physiotherapie. Sie wird dann eingesetzt, wenn der
        Abfluss
        der Zwischenzellflüssigkeit angeregt werden soll. Tatsächlich kann das Lymphsystem auf Bedarf
        schneller
        arbeiten. Mit der Lymphdrainage wollen wir manuell die Lymphgefäße zu einer vermehrten Tätigkeit
        anregen. Dadurch können Schwellungen zurückgehen oder sich komplett auflösen.`,
        prices: [
            {
                duration: "30 Minuten",
                cost: 36.5
            },
            {
                duration: "45 Minuten",
                cost: 57.5
            },
            {
                duration: "60 Minuten",
                cost: 71.5
            },
        ]
    },{
        title: <span>Wärme- und Kältetherapie <i>(WT/HL/EIS)</i></span>,
        text: <span>Bei Wärme- und Kältetherapie gibt es verschiedene Möglichkeiten, den Körper zu behandeln. Bei
        Wärmetherapie bieten wir Ihnen Infrarotlichtbehandlungen und Behandlungen mit Wärmeträgern, welche
        in
        einem Wasserbad erhitzt werden und zusammen mit einer Moorpackung auf den Körper aufgelegt werden.
        <br />
        Bei Kältetherapie arbeiten wir mit Eispacks, die nicht direkt auf die Haut aufgelegt werden.</span>,
        prices: [
            {
                duration: "15 Minuten Wärmetherapie",
                cost: 16.5
            },
            {
                duration: "15 Minuten Kältetherapie/Eis",
                cost: 14.5
            }
        ]
    
    }
]

function Services() {
    
    const [animationDelay, setAnimationDelay] = useState(0)

    useEffect(() => {
        if (animationDelay < services.length) {
            setTimeout(() => {
                setAnimationDelay(animationDelay + 1)
            }, 50)
        }
    }, [animationDelay])

    const [open, setOpen] = useState([])
    const theme = useTheme()

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleAccordionChange = (idx) => (event, isClosed) => {
        if(!isClosed) {
            setOpen(open.filter((i) => i !== idx))
        } else {
            if(isMobile) {
                setOpen([idx])
            } else {
                setOpen([...open, idx])
            }
        }
    };

    return (
        <section id="offers">
            <Grid sx={{ pl: {xs: 3, md: 7, lg: 15}, pr: {xs: 1, md: 7, lg: 15} }} container direction="column" spacing={2}>
                <Grid item style={{paddingLeft: 0}}>
                    <Typography variant='h2' sx={{mb: 5}}>
                        Unsere Angebote
                    </Typography>
                </Grid>
                {services.map((service, idx) => (
                    <Fade key={`service-${idx}`}>
                        <Grid item>
                            <Accordion expanded={open.includes(idx)} onChange={handleAccordionChange(idx)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography variant='h5'>{service.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="body2">
                                        {service.text}
                                    </Typography>
                                    <List container sx={{mt: 2}}>
                                        {service.prices.map((p, idx) => (
                                            <ListItem disablePadding divider key={`cost-${idx}`}>
                                                <Grid container>
                                                    <Grid item xs={8} md={3}>
                                                        {p.duration}
                                                    </Grid>
                                                    <Grid item xs={4} md={9} sx={{textAlign: isMobile ? "right" : 'left'}}>
                                                        {new Intl.NumberFormat('de-DE',  { style:'currency', currency: 'EUR' }).format(p.cost)}
                                                </Grid>
                                                </Grid>
                                            </ListItem>
                                        ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Fade>
                ))}
                
                <Typography variant='subtitle1' sx={{mt: 3, mb: 1, fontWeight: 'bold'}}>
                    Preise gelten für Privatversicherte und Selbstzahler
                </Typography>
                <Typography variant='caption' sx={{textAlign: 'justify'}}>
                Die Privatpreise richten sich nach der GebüTh – Gebührenordnung für Therapeuten und liegen bei einem Steigerungssatz von 1,4 bzw. 1,6-1,8. Als Grundlage dienen die Leistungsbeschreibungen der GKV. Sollten Sie Fragen zu unseren Preisen haben, sprechen Sie uns an. Wir werden Ihnen diese dann gerne beantworten und Sie gegebenenfalls beraten, sollte es zu Problemen bei der Kostenerstattung seitens Ihrer PKV kommen. Einige private Krankenversicherungen versuchen trotz anderslautender Vereinbarungen den Ihnen zu erstattenden Betrag auf den sogenannten Beihilfesatz zu beschränken. Ich weise Sie darauf hin, dass Ihre Krankenkasse diese Honorarbeträge möglicherweise nicht voll ersetzt. In diesem Fall müssen Sie den Differenzbetrag selbst begleichen.
                </Typography>
            </Grid>
        </section>
    )
}

export default Services
