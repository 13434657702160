import React from "react";
import images from "img/normal";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { Typography, useTheme } from "@mui/material";

const diashowImages = [
  images.physio1,
  images.physio2,
  images.physio4,
  images.physio5,
  images.physio7,
  images.physio8,
  images.physio9,
  //images.physio11,
  images.physio17,
  images.physio18,
  images.physio20,
  images.physio22,
];

export const Images = ({dishowOnly}) => {

  const theme = useTheme()

  const fadeProperties = {
    duration: 3000,
    canSwipe: true,
    autoplay: true
  };

  if(!dishowOnly){
    return <section id="images" style={{position: "relative"}}>
    <Typography variant="h3" sx={{position: "absolute",color: "white", textShadow: "1px 1px 3px black", top: theme.spacing(2), left: theme.spacing(2), zIndex: 99}}> 
    Galerie
    </Typography>
    <Slide easing="ease" {...fadeProperties}>
          {
            diashowImages.map((i, idx) => <div className="each-slide" key={idx}>
            <div style={{'backgroundImage': `url(${i})`}}></div>
          </div>)
          }
        </Slide>
    </section>;
  } else {
    return <Slide cssClass={"hero-slider"} arrows={false} easing="ease" {...fadeProperties}>
    {
      diashowImages.map((i, idx) => <div className="each-slide" key={idx}>
      <div style={{'backgroundImage': `url(${i})`}}></div>
    </div>)
    }
  </Slide>
  }
};
  


export default Images;
