import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { easing, Fade, Grid, Hidden, Typography, useTheme } from '@mui/material'
import circle from "img/circle.svg"
import fullLogo from "img/fullLogo.svg"
import scrollindicator from "img/scrollindicator.svg"
import React, { createRef, useEffect, useState } from 'react'
import { Link, scrollSpy } from 'react-scroll'
import Images from './Images'
import MenuBar from "./Menu"


function Hero() {

    //Breakpoint 1150px!

    const [animationDelay, setAnimationDelay] = useState(0)

    const theme = useTheme()

    const [showFilm, setShowFilm] = useState(false)

    useEffect(() => {
        if (animationDelay < 3) {
            setTimeout(() => {
                setAnimationDelay(animationDelay + 1)
            }, 200)
        }
    }, [animationDelay])

    useEffect(() => {

        scrollSpy.update();

    }, [])

    const ref = createRef(null)

    const isIos = ['iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)

    const toggleFilm = () => {
        if (!showFilm) ref.current.volume = 0
        if (ref.current.paused) { ref.current.play() }
        const fadeIn = (bool, vid) => {
            if (!bool) vid.volume += 0.05
            else {
                if (vid.volume > 0.05) {
                    vid.volume -= 0.1
                } else {
                    vid.volume = 0
                }
            }
            if (vid.volume > 0 && vid.volume < 0.5) {
                setTimeout(() => fadeIn(bool, vid), 100)
            }
        }
        fadeIn(showFilm, ref.current)
        ref.current.muted = false
        setShowFilm(!showFilm)
    }

    return (
        <section id="hero">

            <MenuBar fixed />
            {/*<Grid item xs={12}>
                    &nbsp;
                </Grid>*/}

            <Fade easing={{ enter: easing.easeInOut }} in={animationDelay > 0 && !showFilm} timeout={{
                enter: 500,
                exit: 500
            }}>
                <img src={fullLogo} type="image/svg+xml" alt="PhysioLife Muehlheim" id="logo"></img>
            </Fade >
            <Fade in={animationDelay > 1 && !showFilm} timeout={{
                enter: 500,
                exit: 500
            }}>
                <img src={circle} type="image/svg+xml" alt="Gesschwungener Kreis um das Logo" id="circle_with_swing"></img>
            </Fade>
            <Fade in={animationDelay > 2 && !showFilm} timeout={{
                enter: 500,
                exit: 500
            }}>
                <img src={circle} alt="Geschwungener Kreis um den Anreisser" type="image/svg+xml" id="circle"></img>
            </Fade>
            <Fade in={animationDelay > 2 && !showFilm} timeout={{
                enter: 500,
                exit: 500
            }}>
                <div id="summary">
                    Wir begrüßen Sie ganz herzlich bei <b>PhysioLife Mühlheim</b>, der Praxis für Physiotherapie im
                    Gewerbegebiet Süd.<br /><br />
                    Ob vorbeugend, nach Verletzungen oder bei akuten Beschwerden, wir finden gemeinsam mit Ihnen die richtige
                    Behandlungsmethode.<br /><br />
                    Wir unterstützen Sie dabei, den Schmerz hinter sich zu lassen und wieder zu vollen Kräften zu
                    kommen.<br /><br />
                </div>
            </Fade>
            <Hidden mdDown>
                <img src={scrollindicator} id="scrollindicator"  alt="Nach unten scrollen um mehr Informationen zu erhalten"
                    className="animate__animated animate__infinite animate__delay-5s animate__pulse" />
            </Hidden>
            <Hidden mdUp >
                <Grid container direction="column" alignItems="center" id="scrollindicator-mobile">
                    <Grid item style={{marginBottom: '-10px'}} className="animate__animated animate__infinite animate__delay-2s animate__pulse">
                        <Link to="about-us" spy={true} smooth={true} duration={800}>
                            <Typography variant='h6'>Mehr erfahren</Typography>
                        </Link>
                    </Grid>
                    <Grid item className="animate__animated animate__infinite animate__delay-2s animate__pulse">
                        <KeyboardArrowDownIcon />
                    </Grid>
                </Grid>

            </Hidden>
            {/*<Hidden mdDown>
                <Button onClick={toggleFilm} variant="contained" sx={{
                    position: 'absolute',
                    left: theme.spacing(5),
                    bottom: theme.spacing(5),
                    zIndex: 5,
                    padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
                }}>
                    <MovieCreationIcon />  {!showFilm ? 'Film anzeigen' : 'Logo einblenden'}
                </Button>
            </Hidden>
             !isIos ? <video volume="0" ref={ref} autoPlay playsInline muted loop id="videobg">
                <source src={backgroundVideoWebm} type="video/webm" />
            </video> : <img src={backgroundVideoMp4} id="videobg" /> */}
            <Images dishowOnly/>
            <Fade in={!showFilm} timeout={{
                enter: 500,
                exit: 500
            }}>
                <div className="hero-background" />
            </Fade>
            <div className="fadeout-to-about-us">
                &nbsp;
            </div>
        </section>
    )
}

export default Hero
